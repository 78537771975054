// 块
.section {
  min-height: 90vh;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  transform: translateZ(0);

  &.content-sec {
    padding: 8rem 2rem 2rem;
  }
}

// 大标题
.main-title {
  text-align: center;
  h2 {
    position: relative;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: 700;
    span {
      color: var(--color-secondary);
    }
    .bg-text {
      position: absolute;
      top: 50%;
      left: 50%;
      color: var(--color-grey-5);
      transition: all 0.4s ease-in-out;
      z-index: -1;
      transform: translate(-50%, -90%);
      font-weight: 800;
      font-size: 6.3rem;
      white-space: nowrap;
    }
  }
  .border {
    border-top: 2px solid;
    height: 1px;
    margin: 1rem auto 0;
    position: relative;
    width: 60%;
    &:before {
      content: "";
      height: 6px;
      position: absolute;
      top: -5px;
      margin-left: -50px;
      width: 100px;
      vertical-align: middle;
      background-color: var(--color-secondary);
    }
  }
}

// 副标题
.sub-title {
  padding: 2rem 0;
  font-size: 1.5rem;
  text-align: center;
  color: var(--color-grey-1);
}

// 通用btn
.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  a {
    border-radius: 30px;
    color: var(--color-white);
    font-weight: 600;
    position: relative;
    border: 1px solid var(--color-secondary);
    display: flex;
    align-items: center;
    overflow: hidden;

    &:hover {
      .bg {
        right: 0;
        opacity: 1;
      }
    }

    .btn-text {
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0.9rem 0.5rem 0.9rem 2.4rem;
      line-height: 1;
      z-index: 1;
      min-width: 5em;
      text-align: center;
    }

    .btn-icon {
      background-color: none;
      // display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 1rem 1.4rem;
      z-index: 1;
    }

    .btn-icon-deploy {
      i {
      position: relative;
      left: -0.3em;
      }
    }

    .bg {
      position: absolute;
      top: 0;
      // right: -100%;
      width: 100%;
      height: 100%;
      background-color: var(--color-secondary);
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}
.btn-container-group {
  display: flex;
  .btn-container {
    display: inline-flex;
    & + .btn-container {
      margin-left: 2rem;
    }
  }
}
