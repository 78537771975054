@media only screen and (max-width: 1000px) {
  html {
    .name h1 {
      font-size: 2rem;
      // padding-top: 90vh !important;
    }
    .main-title {
      h2 {
        font-size: 2.4rem;

        .bg-text {
          font-size: 3rem;
        }
      }
    }

    .section {
      &.content-sec {
        padding: 4rem 2rem 2rem;
      }
    }

    .btn-container-group {
      flex-direction: column;

      .btn-container,
      .btn-container + .btn-container {
        margin: 1rem auto;
      }
    }

    .header-bar {
      &.open-menu {
        background-color: var(--color-white);
        .nav {
          display: flex;
        }
      }
      .nav {
        display: none;
        position: fixed;
        top: 3.6rem;
        left: 0;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: var(--color-white);

        .item {
          color: var(--color-black);
          padding: 1rem 0;
          border-bottom: 1px solid var(--color-grey-1);

          &:hover {
            color: var(--color-secondary) !important;
          }

          & .fa-solid {
            display: none;
          }

          & ul {
            display: block !important;
            position: static !important;
            box-shadow: none !important;
            margin-top: 0.5rem;

            li {
              border-bottom: 0;
              &:hover {
                background-color: transparent !important;
                color: var(--color-secondary) !important;
              }
            }
          }
        }
      }

      .menu {
        display: block;
      }
    }

    .sec1 {
      position: relative;
      height: 100vh;
      overflow: auto;
      .left-header {
        width: 100%;
        .h-shape {
          clip-path: polygon(0 0, 10% 0, 60% 100%, 0% 100%);
        }
        .image {
          display: none;
        }
      }

      .right-header {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 0 10vw;
        background-color:  var(--color-primary);

        .name {
          font-size: 2rem;
          margin-top: 20vh;
        }

        p {
          font-size: clamp(1.2rem, 2.5vw, 2rem);
          line-height: 1.8rem;
          margin-bottom: 1rem;
          text-align: left;
          padding-left: 5vw;
        }


      }
    }

    .sec2 {
      .about-cards {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .sec3 {
      .categories {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .sec4 {
      .scenes-box {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .sec5 {
      .news {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .sec6 {
      .contact-content-con {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: border-box;

        .left-contact {
          & > h4 {
            text-align: center;
          }
        }

        .right-contact {
          margin: 0;
          flex: 1;
          flex-grow: 1;
          width: 100%;

          .input-control {
            input,
            textarea {
              display: block;
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}
