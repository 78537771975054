// 主样式
@import "./common.scss";
@import "./media.scss";

// header
.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 100;
  transition: all 0.2s ease-in-out;

  &.scroll {
    background-color: var(--color-white);

    .nav {
      .item {
        color: var(--color-primary);
      }
    }

    .theme,
    .lang,
    .menu {
      box-shadow: none;
      &:hover {
        color: var(--color-white);
        background-color: var(--color-secondary);
      }
    }
  }

  .logo {
    height: 4.5rem;
    img {
      display: block;
      width: auto;
      height: 100%;
    }
  }

  .mainer {
    display: flex;
    align-items: center;
  }

  .nav {
    display: flex;
    .item {
      font-size: 1.2rem;
      font-weight: 700;
      color: var(--color-white);
      margin: 0 1rem;
      cursor: pointer;
      position: relative;
      padding: 4px 0;
      transition: all 0.2s ease-in-out;
      position: relative;

      & > .fa-solid {
        transition: all 0.2s ease-in-out;
      }

      & > ul {
        display: none;
        position: absolute;
        top: 1.9rem;
        left: 0;
        background-color: var(--color-white);
        color: var(--color-primary);
        z-index: 2;
        padding: 3px 0;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
        transition: all 0.2s ease-in-out;
        box-sizing: border-box;
        border-radius: 4px;

        li {
          padding: 0.4rem 0.9rem;
          border-bottom: 1px solid var(--color-grey-1);
          font-size: 1.1rem;
          font-weight: normal;
          position: relative;
          white-space: nowrap;

          & .fa-angle-down {
            transition: all 0.2s ease-in-out;
          }

          & ul {
            display: none;
            position: absolute;
            top: 0;
            left: 100%;
            background-color: var(--color-white);
            color: var(--color-primary);
            z-index: 2;
            padding: 3px 0;
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
            transition: all 0.2s ease-in-out;
            box-sizing: border-box;
            overflow: hidden;
            border-radius: 0 4px 4px 0;
          }

          &:hover {
            background-color: var(--color-secondary);
            color: var(--color-white);

            & > ul {
              display: block;
            }

            & > .fa-angle-down {
              transform: rotate(-90deg);
            }
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 0;
        background-color: var(--color-secondary);
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        color: var(--color-secondary);

        & > .fa-solid {
          transform: rotate(180deg);
        }

        & > ul {
          display: block;
        }

        &::after {
          width: 100%;
        }
      }
    }
  }

  .theme {
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.23);
    width: 1.8rem;
    height: 1.8rem;
    text-align: center;
    line-height: 1.8rem;
    border-radius: 2rem;
    background-color: var(--color-white);
    color: var(--color-primary);
    font-size: 0.8rem;
    &:hover {
      color: var(--color-primary);
      background-color: var(--color-secondary);
    }
  }

  .lang,
  .menu {
    margin-left: 0.5rem;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.23);
    height: 1.8rem;
    text-align: center;
    line-height: 1.8rem;
    border-radius: 2rem;
    padding: 0 10px;
    background-color: var(--color-white);
    color: var(--color-primary);
    font-size: 0.8rem;
    &:hover {
      color: var(--color-primary);
      background-color: var(--color-secondary);
    }
  }

  .menu {
    display: none;
  }
}
.white-header {
  .header-bar {
    background-color: var(--color-white);

    .nav {
      .item {
        color: var(--color-primary);
      }
    }

    .theme,
    .lang,
    .menu {
      box-shadow: none;
      &:hover {
        color: var(--color-white);
        background-color: var(--color-secondary);
      }
    }
  }
}

// home
.sec1 {
  display: flex;
  height: 100vh;
  overflow: auto;


  .left-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
    flex-shrink: 0;

    .h-shape {
      transition: all 0.4s ease-in-out;
      width: 65%;
      height: 100%;
      background-color: var(--color-secondary);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      clip-path: polygon(0 0, 56% 0, 90% 100%, 0% 100%);
    }

    .image {
      width: 68%;
      height: auto;
      // transform: translateX(-10%);
      transition: all 0.6s ease-in-out;
      border-radius: 10px;

      &:hover {
        filter: grayscale(50%);
      }
    }
  }

  .right-header {

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 15rem;
    padding-right: 15rem;
    margin-bottom: 15rem;


    .name {
      font-size: clamp(1.8rem, 3vw, 4.1rem);
      padding-top: 10vh;
      span {
        color: var(--color-secondary);
      }
    }

    p {
      line-height: 2rem;
      font-size: clamp(1.4rem, 1.4vw, 3.6rem);
      white-space: pre-wrap;
      word-wrap: break-word;
      margin-top: 0.2rem;
    }
    .btn-container {
      a {
        margin: 0 auto;
        background-color: var(--color-primary);
      }
      .btn-text {
        font-size: clamp(1rem, 1.3vw, 2rem);
      }
    }
  }
}

// about
.sec2 {
  .about-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 2rem auto 0;
    width: 80%;

    .block {
      text-align: center;
      background-color: var(--color-grey-5);
      padding: 2rem;
      border-bottom: 2px solid transparent;
      transition: 0.5s all ease-in-out;
      margin-bottom: 1rem;
      border-radius: 10px;

      &:hover {
        border-bottom: 3px solid var(--color-secondary);

        .icon-box {
          transform: translateY(-10px);
          color: inherit;
          border: 0;
          &::before {
            width: 100%;
            height: 100%;
            background-color: var(--color-secondary);
            transform: translateX(0);
            transition: all 0.2s ease-out;
          }
        }
      }

      .about-text {
        margin-top: 2rem;
        cursor: default;

        h3 {
          font-size: 1.5rem;
        }

        p {
          margin-top: 1rem;
          line-height: 1.5rem;
          color: var(--color-grey-1);
        }
      }

      .icon-box {
        position: relative;
        transition-duration: 0.3s;
        font-size: 50px;
        margin-left: 20px;
        margin-right: 20px;
        padding: 20px 0;
        border: 2px solid var(--color-secondary);
        border-radius: 5px 20px 5px;
        color: var(--color-secondary);
        overflow: hidden;
        transition: all 0.4s ease-out;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          z-index: -2;
          transform: translateX(0%);
          transition: all 0.4s ease-out;
          box-shadow: 0px 4px 20px rgba($color: #000000, $alpha: 0.8);
        }
      }
    }
  }
}

// product
.sec3 {
  .categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 2rem auto 0;
    width: 80%;

    .category {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      border: 2px solid var(--color-secondary);

      &:hover .hover-items {
        opacity: 1;
        transform: scale(1);
      }

      .image {
        text-align: center;

        h3 {
          font-size: 1.5rem;
          padding: 1rem 0;
        }
      }

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: inherit;
      }

      .hover-items {
        width: 100%;
        height: 100%;
        background-color: var(--color-secondary);
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        border-radius: 8px;
        transition: opacity 0.25s ease-in;

        p {
          font-size: 1.4em;
          font-weight: 600;
          text-align: center;
          color: var(--color-primary);
          padding: 1rem 1rem 1rem;
        }

        .show-more {
          background-color: var(--color-primary);
          padding: 1rem;
          border-radius: 10px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}

// solution
.sec4 {
  .scenes-box {
    width: 80%;
    margin: 2rem auto 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    position: relative;

    .item {
      height: 20rem;
      position: relative;
      border-radius: 10px;
      cursor: pointer;
      border: 2px solid var(--color-secondary);
      box-sizing: border-box;

      &:hover {
        box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
        .img {
          transform: scale(1.1);
          box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
        }

        .text {
          opacity: 0;
        }
      }

      .img,
      .img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
      }

      .text {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80%;
        height: 7rem;
        background-color: var(--color-secondary);
        box-sizing: border-box;
        padding: 1rem;
        border-radius: 5px 0 0 0;
        transition: all 0.2s ease-in-out;

        h3 {
          font-size: 1.2rem;
        }

        p {
          margin-top: 0.8rem;
          line-height: 1.2;
          color: var(--color-grey-1);
        }
      }
    }
  }
}

// news
.sec5 {
  .news {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    margin: 2rem auto;
    width: 80%;
  }
  .new {
    position: relative;
    background-color: var(--color-grey-5);
    border-radius: 10px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;
    cursor: pointer;

    &:hover {
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);

      img {
        filter: grayscale(0);
        transform: scale(1.1);
        box-shadow: 1px 4px 40px rgba(0, 0, 0, 0.4);
        border-radius: 5px;
      }
    }

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      //filter: grayscale(100%);
      transition: all 0.4s ease-in-out;
    }
    .news-text {
      margin-top: -7px;
      padding: 1.1rem;
      border-top: 8px solid var(--color-secondary);
      h4 {
        font-size: 1.5rem;
        margin-bottom: 0.7rem;
        transition: all 0.4s ease-in-out;
        cursor: pointer;

        &:hover {
          color: var(--color-secondary);
        }
      }

      p {
        color: var(--color-grey-2);
        line-height: 1.5rem;
        padding-bottom: 1rem;
      }
    }
  }
}

// contact
.sec6 {
  .contact-content-con {
    display: flex;
    width: 80%;
    margin: 2rem auto 0;
    .left-contact {
      flex: 2;

      h4 {
        margin-top: 1rem;
        font-size: 2rem;
        text-transform: uppercase;
      }

      p {
        margin: 1rem 0;
        line-height: 2rem;
      }

      .contact-info {
        i {
          margin-right: 0.4rem;
        }
        .contact-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin: 0.3rem 0 !important;
            padding: 0 !important;
          }
        }
      }

      .contact-icon {
        display: flex;
        margin-top: 2rem;
        a {
          display: flex;
          align-items: center;
          color: var(--color-white);
          background-color: var(--color-grey-5);
          cursor: pointer;
          justify-content: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin: 0 0.4rem;
          transition: all 0.4s ease-in-out;
          &:hover {
            background-color: var(--color-secondary);
            i {
              color: var(--color-primary);
            }
          }
          i {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
          }
        }
      }
    }

    .right-contact {
      flex: 3;
      margin-left: 3rem;
      .input-control {
        margin: 1.5rem 0;
        input,
        textarea {
          border-radius: 30px;
          font-weight: inherit;
          font-size: inherit;
          font-family: inherit;
          padding: 0.8rem 1.1rem;
          outline: none;
          border: none;
          background-color: var(--color-grey-5);
          width: 100%;
          color: var(--color-white);
          resize: none;
        }
      }

      .i-c-2 {
        display: flex;
        :last-child {
          margin-left: 1.5rem;
        }
      }
    }
  }
}

// footer
.footer {
  color: var(--color-white);
  padding: 2rem;
  text-align: center;

  span,
  a {
    color: inherit;
  }

  a:hover {
    text-decoration: underline;
  }
}

// add by penzai
